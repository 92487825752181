import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef } from 'react';
import theme from '../../theme';
import { Button } from '../../elements';

const BaseDialog = styled.dialog`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${theme.zIndices.modal};

    padding: 20px;
    background-color: ${theme.colors.UI100};
    border: 0px;
    border-radius: 5px;
    margin: unset;
     
    &::backdrop {
        background-color: rgba(0, 0, 0, 0.65);
        z-index: ${theme.zIndices.modal - 1};
    }

    button.icon-close {
        appearance: none;
        border: unset;

        position: absolute;
        top: 10px;
        right: 10px;

        font-size: 1rem;
        cursor: pointer;
        z-index: 1;
        border-radius: 5px;
    }

    p.outro {
        font-size: 0.8rem;
        opacity: 0.75;

        text-align: center;
        max-width: 70ch;
        margin-inline: auto;
    }

    h1, p {
        color: ${theme.colors.textSecondary};
    }

    h1 {
        margin: 0px 0px 5px 0px;
    }

    h2 {
        margin: 20px 0px 5px 0px;
    }

    h4 {
        margin: unset;
    }

    h4 {
        font-size: 0.9rem;
        opacity: 0.75;
    }

    h3 {
        font-size: 1.2rem;
    }

    ul {
        margin: unset;
    }

    li, p {
        font-size: 1rem;
        max-width: 70ch;
        margin-inline: auto; 
        margin: unset;
    }

    .close-button {
        margin-block: 15px;
    }
`;

export default function WelcomeModal({lastUpdated, onHideUntilNextUpdate}) {
    const dialogRef = useRef();

    useEffect(() => {
        if (dialogRef) {
            dialogRef.current.showModal();
        }
    }, [dialogRef]);

    const closeDialog = useCallback((hideUntilNextUpdate) => {
        dialogRef.current.close();

        if (hideUntilNextUpdate) {
            onHideUntilNextUpdate();
        }
    }, [dialogRef]);

    return (
        <BaseDialog onClick={() => closeDialog(false)} ref={dialogRef}>
            <div onClick={(evt) => evt.stopPropagation()}>
                <button 
                    className='icon-close'
                    tabIndex="-1"
                    onClick={() => closeDialog(false)}
                >✖</button>

                <h4>Last Updated: { new Date(lastUpdated).toLocaleDateString('en-US', { month: 'long', year: 'numeric', day: 'numeric' }) }</h4>
                <h1>Welcome to Stratbook.gg!</h1>
                <h2>Recent Updates</h2>
                <ul>
                    <li>
                        Updates for Y10S1&apos;s launch
                    </li>
                    <li>
                        Adds support for Rauora
                    </li>
                </ul>

                <h2>Private Beta</h2>
                <p>
                    We&apos;ve kept Stratbook.gg updated for years, and now it&apos;s
                    time for a major upgrade! Interested in exploring our private
                    beta once it&apos;s ready? Email us at
                    { ' ' }
                    <a href="mailto:support@stratbook.gg">support@stratbook.gg</a>
                    { ' ' }
                    to join the waitlist. We&apos;re excited to show you what&apos;s next!
                </p>

                <Button className='close-button' onClick={() => closeDialog(false)}>
                    Close For Now
                </Button>

                <Button className='close-button' onClick={() => closeDialog(true)}>
                    Close &amp; Hide Until Next Update
                </Button>

                <p className='outro'>
                    If you find any bugs, or would like to be added to
                    the private beta&apos;s waitlist, please send an email to { ' ' }
                    <a href="mailto:support@stratbook.gg">support@stratbook.gg</a>
                </p>
            </div>
        </BaseDialog>
    );
}

