import icons from '../assets/icons';
import {utilities} from './utility';

function Operator(name, isAttacker, icon, primaryGadget, secondaryGadgets, droneCount, defaultColor, hasGonneSix = false) {
    this.name = name;
    this.isAttacker = isAttacker;
    this.icon = icon;
    this.primaryGadget = primaryGadget;
    this.secondaryGadgets = secondaryGadgets;
    this.hasGonneSix = hasGonneSix;
    this.player = '';
    this.droneCount = droneCount;
    this.color = defaultColor;

    this.hasUtility = (utilityName) => {
        if (utilityName === utilities.Drone.name && isAttacker === true) return true;
        if (utilityName === utilities.Reinforcement.name && isAttacker === false) return true;
        if (utilityName === utilities.Barricade.name && isAttacker === false) return true;
        if (utilityName === utilities.Rotate.name && isAttacker === false) return true;

        if (utilityName === primaryGadget.name) return true;
        if (secondaryGadgets.map(({name}) => name).includes(utilityName)) return true;
    };

    this.utilityCounts = (secondaryIndices) => {
        const utilityCountObject = {
            [utilities.Reinforcement.name]: 2 // Always 2 so 2*5 = 10.
        };

        primaryGadget.forEach(primaryGadget => {
            if (primaryGadget && isFinite(primaryGadget.count)) utilityCountObject[primaryGadget.name] = primaryGadget.count;
        });

        if (secondaryIndices === undefined) {
            secondaryGadgets.forEach(secondaryGadget => {
                if (secondaryGadget) utilityCountObject[secondaryGadget.name] = secondaryGadget.count;
            });
        } else {
            if (secondaryIndices instanceof Array) {
                for (const secondaryIndex of secondaryIndices) {
                    const secondaryGadget = secondaryGadgets[secondaryIndex];
                    if (secondaryGadget.name !== utilities.Unknown.name) utilityCountObject[secondaryGadget.name] = secondaryGadget.count;
                }
            } else {
                const secondaryGadget = secondaryGadgets[secondaryIndices];
                if (secondaryGadget.name !== utilities.Unknown.name) utilityCountObject[secondaryGadget.name] = secondaryGadget.count;
            }
        }

        if (isAttacker) utilityCountObject[utilities.Drone.name] = droneCount;

        return utilityCountObject;
    };
}

const Ace = new Operator('Ace', true, icons.AceIcon, [utilities.AceGadget], [utilities.BreachCharge, utilities.Claymore], 2, '#2b7e90');
const Alibi = new Operator('Alibi', false, icons.AlibiIcon, [utilities.AlibiGadget], [utilities.ProximityAlarm, utilities.ObservationBlocker], 0, '#666e24');
const Amaru = new Operator('Amaru', true, icons.AmaruIcon, [utilities.AmaruGadget], [utilities.StunGrenade, utilities.HardbreachTool], 2, '#15580c', true);
const Aruni = new Operator('Aruni', false, icons.AruniIcon, [utilities.AruniGadget], [utilities.BulletproofCamera, utilities.BarbedWire], 0, '#c23b11');
const Ash = new Operator('Ash', true, icons.AshIcon, [utilities.AshGadget], [utilities.BreachCharge, utilities.Claymore], 2, '#9f4525');
const Azami = new Operator('Azami', false, icons.AzamiIcon, [utilities.AzamiGadget], [utilities.BarbedWire, utilities.ImpactGrenade], 0, '#4479c3');
const Bandit = new Operator('Bandit', false, icons.BanditIcon, [utilities.BanditGadget], [utilities.BarbedWire, utilities.NitroCell], 0, '#f8a230');
const Blackbeard = new Operator('Blackbeard', true, icons.BlackbeardIcon, [utilities.BlackbeardGadget], [utilities.Claymore, utilities.FragGrenade], 2, '#ca9932');
const Blitz = new Operator('Blitz', true, icons.BlitzIcon, [utilities.BlitzGadget], [utilities.SmokeGrenade, utilities.BreachCharge], 2, '#fbdb6a');
const Brava = new Operator('Brava', true, icons.BravaIcon, [utilities.BravaGadget], [utilities.SmokeGrenade, utilities.Claymore], 2, '#43adf1');
const Buck = new Operator('Buck', true, icons.BuckIcon, [utilities.BuckGadget], [utilities.StunGrenade, utilities.Claymore], 2, '#53b2c9');
const Capitao = new Operator('Capitao', true, icons.CapitaoIcon, [utilities.CapitaoFireGadget, utilities.CapitaoSmokeGadget], [utilities.Claymore, utilities.HardbreachTool], 2, '#8cc363', true);
const Castle = new Operator('Castle', false, icons.CastleIcon, [utilities.CastleGadget], [utilities.ProximityAlarm, utilities.BulletproofCamera], 0, '#d75b2b');
const Caveira = new Operator('Caveira', false, icons.CaveiraIcon, [utilities.CaveiraGadget], [utilities.ProximityAlarm, utilities.ImpactGrenade, utilities.ObservationBlocker], 0, '#79b559');
const Clash = new Operator('Clash', false, icons.ClashIcon, [utilities.ClashGadget], [utilities.BarbedWire, utilities.ImpactGrenade], 0, '#768696');
const Deimos = new Operator('Deimos', true, icons.DeimosIcon, [utilities.DeimosGadget], [utilities.FragGrenade, utilities.HardbreachTool, utilities.SmokeGrenade], 0, '#ec4644');
const Doc = new Operator('Doc', false, icons.DocIcon, [utilities.DocGadget], [utilities.BulletproofCamera, utilities.BarbedWire], 0, '#30618c');
const Dokkaebie = new Operator('Dokkaebie', true, icons.DokkaebiIcon, [utilities.DokkaebiGadget], [utilities.SmokeGrenade, utilities.StunGrenade, utilities.ImpactEmp], 2, '#c5cfd1', true);
const Echo = new Operator('Echo', false, icons.EchoIcon, [utilities.EchoGadget], [utilities.ImpactGrenade, utilities.DeployableShield], 0, '#9a273f');
const Ela = new Operator('Ela', false, icons.ElaIcon, [utilities.ElaGadget], [utilities.BarbedWire, utilities.DeployableShield, utilities.ImpactGrenade], 0, '#409392');
const Fenrir = new Operator('Fenrir', false, icons.FenrirIcon, [utilities.FenrirGadget], [utilities.ObservationBlocker, utilities.BulletproofCamera], 0, '#4e41a7');
const Finka = new Operator('Finka', true, icons.FinkaIcon, [utilities.FinkaGadget], [utilities.StunGrenade, utilities.SmokeGrenade], 2, '#fcae1d', true);
const Flores = new Operator('Flores', true, icons.FloresIcon, [utilities.FloresGadget], [utilities.StunGrenade, utilities.Claymore], 2, '#8c0000');
const Frost = new Operator('Frost', false, icons.FrostIcon, [utilities.FrostGadget], [utilities.BulletproofCamera, utilities.DeployableShield], 0, '#3094b2');
const Fuze = new Operator('Fuze', true, icons.FuzeIcon, [utilities.FuzeGadget], [utilities.BreachCharge, utilities.HardbreachTool, utilities.SmokeGrenade], 2, '#9f1d1d');
const Glaz = new Operator('Glaz', true, icons.GlazIcon, [utilities.GlazGadget], [utilities.FragGrenade, utilities.SmokeGrenade, utilities.Claymore], 2, '#9d2424', true);
const Goyo = new Operator('Goyo', false, icons.GoyoIcon, [utilities.GoyoGadget], [utilities.ProximityAlarm, utilities.BulletproofCamera, utilities.ImpactGrenade], 0, '#135a08');
const Gridlock = new Operator('Gridlock', true, icons.GridlockIcon, [utilities.GridlockGadget], [utilities.SmokeGrenade, utilities.FragGrenade, utilities.ImpactEmp], 2, '#ff0971');
const Grim = new Operator('Grim', true, icons.GrimIcon, [utilities.GrimGadget], [utilities.BreachCharge, utilities.Claymore, utilities.HardbreachTool], 2, '#d8c522');
const Hibana = new Operator('Hibana', true, icons.HibanaIcon, [utilities.HibanaGadget, ], [utilities.StunGrenade, utilities.BreachCharge], 2, '#9f344a');
const Iana = new Operator('Iana', true, icons.IanaIcon, [utilities.IanaGadget], [utilities.ImpactEmp, utilities.SmokeGrenade], 2, '#98779c');
const Iq = new Operator('Iq', true, icons.IqIcon, [utilities.IqGadget], [utilities.BreachCharge, utilities.Claymore], 2, '#fabd55');
const Jackal = new Operator('Jackal', true, icons.JackalIcon, [utilities.JackalGadget], [utilities.Claymore, utilities.SmokeGrenade], 2, '#7f479a');
const Jager = new Operator('Jager', false, icons.JagerIcon, [utilities.JagerGadget], [utilities.BulletproofCamera, utilities.ObservationBlocker], 0, '#ebb62e');
const Kaid = new Operator('Kaid', false, icons.KaidIcon, [utilities.KaidGadget], [utilities.BarbedWire, utilities.NitroCell, utilities.ObservationBlocker], 0, '#b48f5d');
const Kali = new Operator('Kali', true, icons.KaliIcon, [utilities.KaliGadget], [utilities.SmokeGrenade, utilities.BreachCharge, utilities.Claymore], 2, '#28c1cd');
const Kapkan = new Operator('Kapkan', false, icons.KapkanIcon, [utilities.KapkanGadget], [utilities.ImpactGrenade, utilities.BarbedWire], 0, '#b43435');
const Lesion = new Operator('Lesion', false, icons.LesionIcon, [utilities.LesionGadget], [utilities.ObservationBlocker, utilities.BulletproofCamera], 0, '#aa4623');
const Lion = new Operator('Lion', true, icons.LionIcon, [utilities.LionGadget], [utilities.StunGrenade, utilities.Claymore, utilities.ImpactEmp], 2, '#fcae1d', true);
const Maestro = new Operator('Maestro', false, icons.MaestroIcon, [utilities.MaestroGadget], [utilities.BarbedWire, utilities.ImpactGrenade, utilities.ObservationBlocker], 0, '#727929');
const Maverick = new Operator('Maverick', true, icons.MaverickIcon, [utilities.MaverickGadget], [utilities.StunGrenade, utilities.Claymore], 2, '#768696');
const Melusi = new Operator('Melusi', false, icons.MelusiIcon, [utilities.MelusiGadget], [utilities.ImpactGrenade, utilities.BulletproofCamera], 0, '#358496');
const Mira = new Operator('Mira', false, icons.MiraIcon, [utilities.MiraGadget], [utilities.ProximityAlarm, utilities.NitroCell], 0, '#8b56a4');
const Montagne = new Operator('Montagne', true, icons.MontagneIcon, [utilities.MontagneGadget], [utilities.SmokeGrenade, utilities.HardbreachTool, utilities.ImpactEmp], 2, '#3b6182');
const Mozzie = new Operator('Mozzie', false, icons.MozzieIcon, [utilities.MozzieGadget], [utilities.BarbedWire, utilities.Nitro, utilities.ImpactGrenade], 0, '#ff0971');
const Mute = new Operator('Mute', false, icons.MuteIcon, [utilities.MuteGadget], [utilities.BulletproofCamera, utilities.NitroCell], 0, '#906f79');
const Nokk = new Operator('Nokk', true, icons.NokkIcon, [utilities.NokkGadget], [utilities.FragGrenade, utilities.HardbreachTool, utilities.ImpactEmp], 2, '#364a93');
const Nomad = new Operator('Nomad', true, icons.NomadIcon, [utilities.NomadGadget], [utilities.BreachCharge, utilities.StunGrenade], 2, '#bb9867');
const Oryx = new Operator('Oryx', false, icons.OryxIcon, [utilities.OryxGadget], [utilities.BarbedWire, utilities.ProximityAlarm], 0, '#7a587d');
const Osa = new Operator('Osa', true, icons.OsaIcon, [utilities.OsaGadget], [utilities.SmokeGadget, utilities.Claymore, utilities.ImpactEmp], 2, '#f9a11b');
const Pulse = new Operator('Pulse', false, icons.PulseIcon, [utilities.PulseGadget], [utilities.DeployableShield, utilities.NitroCell, utilities.ObservationBlocker], 0, '#da6639');
const Ram = new Operator('Ram', true, icons.RamIcon, [utilities.RamGadget], [utilities.SmokeGrenade, utilities.StunGrenade], 2, '#ed7a4b');
const Rauora = new Operator('Rauroa', true, icons.RauoraIcon, [utilities.RauoraGadget], [utilities.SmokeGrenade, utilities.BreachCharge], 2, '#d77237');
const Rook = new Operator('Rook', false, icons.RookIcon, [utilities.RookGadget], [utilities.ProximityAlarm, utilities.ImpactGrenade, utilities.ObservationBlocker], 0, '#284a68');
const Sens = new Operator('Sens', true, icons.SensIcon, [utilities.SensGadget], [utilities.HardbreachTool, utilities.Claymore], 2, '#70c6b0', true);
const Sledge = new Operator('Sledge', true, icons.SledgeIcon, [utilities.SledgeGadget], [utilities.FragGrenade, utilities.StunGrenade, utilities.ImpactEmp], 2, '#a58c94');
const Smoke = new Operator('Smoke', false, icons.SmokeIcon, [utilities.SmokeGadget], [utilities.ProximityAlarm, utilities.BarbedWire], 0, '#a2868f');
const Solis = new Operator('Solis', false, icons.SolisIcon, [utilities.SolisGadget], [utilities.BulletproofCamera, utilities.ProximityAlarm], 0, '#cf3314');
const Tachanka = new Operator('Tachanka', false, icons.TachankaIcon, [utilities.TachankaGadget], [utilities.BarbedWire, utilities.DeployableShield, utilities.ProximityAlarm], 0, '#892125');
const Thatcher = new Operator('Thatcher', true, icons.ThatcherIcon, [utilities.ThatcherGadget], [utilities.BreachCharge, utilities.Claymore], 2, '#906f79');
const Thermite = new Operator('Thermite', true, icons.ThermiteIcon, [utilities.ThermiteGadget], [utilities.SmokeGrenade, utilities.StunGrenade], 2, '#cc5629');
const Thorn = new Operator('Thorn', false, icons.ThornIcon, [utilities.ThornGadget], [utilities.BarbedWire, utilities.DeployableShield], 0, '#587624');
const Thunderbird = new Operator('Thunderbird', false, icons.ThunderbirdIcon, [utilities.ThunderbirdGadget], [utilities.BarbedWire, utilities.BulletproofCamera], 0, '#864236');
const Tubarao = new Operator('Tubarao', false, icons.TubaraoIcon, [utilities.TubaraoGadget], [utilities.NitroCell, utilities.ProximityAlarm], 0, '#3e6d71');
const Twitch = new Operator('Twitch', true, icons.TwitchIcon, [utilities.TwitchGadget], [utilities.SmokeGrenade, utilities.Claymore], 2, '#294f73');
const Valkyrie = new Operator('Valkyrie', false, icons.ValkyrieIcon, [utilities.ValkyrieGadget], [utilities.ImpactGrenade, utilities.NitroCell], 0, '#c9962e');
const Vigil = new Operator('Vigil', false, icons.VigilIcon, [utilities.VigilGadget], [utilities.BulletproofCamera, utilities.ImpactGrenade], 0, '#c6d0d2');
const Wamai = new Operator('Wamai', false, icons.WamaiIcon, [utilities.WamaiGadget], [utilities.ProximityAlarm, utilities.ImpactGrenade], 0, '#28c1cd');
const Warden = new Operator('Warden', false, icons.WardenIcon, [utilities.WardenGadget], [utilities.DeployableShield, utilities.NitroCell, utilities.ObservationBlocker], 0, '#3d50a3'); 
const Ying = new Operator('Ying', true, icons.YingIcon, [utilities.YingGadget], [utilities.SmokeGrenade, utilities.HardbreachTool], 2, '#aa4927');
const Zero = new Operator('Zero', true, icons.ZeroIcon, [utilities.ZeroGadget], [utilities.Claymore, utilities.HardbreachTool], 2, '#72ad48', true);
const Zofia = new Operator('Zofia', true, icons.ZofiaIcon, [utilities.ZofiaImpactGadget, utilities.ZofiaConcussionGadget], [utilities.HardbreachTool, utilities.Claymore], 2, '#5ba2a1');
const Skopos = new Operator('Skopos', false, icons.SkoposIcon, [utilities.SkoposTalosGadget, utilities.SkoposKolossosGadget], [utilities.ImpactGrenade, utilities.ProximityAlarm], 0, '#da54d4');

const attackerSecondaries = [utilities.SmokeGrenade, utilities.Claymore, utilities.StunGrenade, utilities.HardbreachTool, utilities.BreachCharge, utilities.FragGrenade, utilities.ImpactEmp];
const defenderSecondaries = [utilities.ImpactGrenade,utilities.NitroCell, utilities.BulletproofCamera, utilities.BarbedWire, utilities.ProximityAlarm, utilities.DeployableShield, utilities.ObservationBlocker];

// Secondary utilities 1 and 2 are unknown so that users
// who unselect an operator don't sometimes end up with a
// smoke unknown. This way they only get unknowns with
// secondary utility if they seek it out.
const Unknown = new Operator('Unknown', undefined, icons.UnknownIcon, [undefined], [utilities.Unknown, ...attackerSecondaries, ...defenderSecondaries], 0, '#000000');

// Sentry & Striker's unique ability is that they can use 2 secondary gadgets.
const Striker = new Operator('Striker', true, icons.StrikerIcon, [undefined], attackerSecondaries, 2, '#ff8e2d', false);
const Sentry = new Operator('Sentry', false, icons.SentryIcon, [undefined], defenderSecondaries, 0, '#ff8e2d', false);


const operators = {
    Osa,
    Flores,
    Zero,
    Ace,
    Azami,
    Iana,
    Kali,
    Amaru,
    Nokk,
    Gridlock,
    Grim,
    Nomad,
    Maverick,
    Lion,
    Fenrir,
    Finka,
    Dokkaebie,
    Zofia,
    Ying,
    Jackal,
    Hibana,
    Capitao,
    Blackbeard,
    Buck,
    Sens,
    Sledge,
    Thatcher,
    Ash,
    Thermite,
    Montagne,
    Twitch,
    Blitz,
    Brava,
    Iq,
    Fuze,
    Glaz,
    Thunderbird,
    Aruni,
    Melusi,
    Oryx,
    Wamai,
    Goyo,
    Warden,
    Mozzie,
    Kaid,
    Clash,
    Maestro,
    Alibi,
    Vigil,
    Ela,
    Lesion,
    Mira,
    Echo,
    Caveira,
    Valkyrie,
    Frost,
    Mute,
    Smoke,
    Solis,
    Castle,
    Pulse,
    Doc,
    Rook,
    Jager,
    Bandit,
    Tachanka,
    Kapkan,
    Thorn,
    Unknown,
    Deimos,
    Tubarao,
    Ram,
    Rauora,
    Striker,
    Sentry,
    Skopos,
};

function get_by_icon(icon) {
    for (const [key, value] of Object.entries(operators)) {
        if (value.icon === icon) {
            return operators[key];
        }
    }
}

export default operators;
export {
    Osa,
    Thunderbird,
    Flores,
    Aruni,
    Zero,
    Ace,
    Azami,
    Melusi,
    Oryx,
    Iana,
    Wamai,
    Kali,
    Amaru,
    Goyo,
    Nokk,
    Warden,
    Mozzie,
    Gridlock,
    Grim,
    Nomad,
    Kaid,
    Clash,
    Maverick,
    Maestro,
    Alibi,
    Lion,
    Fenrir,
    Finka,
    Vigil,
    Dokkaebie,
    Zofia,
    Ela,
    Ying,
    Lesion,
    Mira,
    Jackal,
    Hibana,
    Echo,
    Caveira,
    Capitao,
    Blackbeard,
    Valkyrie,
    Buck,
    Frost,
    Mute,
    Sens,
    Sledge,
    Smoke,
    Solis,
    Thatcher,
    Ash,
    Castle,
    Pulse,
    Thermite,
    Montagne,
    Twitch,
    Doc,
    Rook,
    Jager,
    Bandit,
    Blitz,
    Brava,
    Iq,
    Fuze,
    Glaz,
    Tachanka,
    Kapkan,
    Unknown,
    get_by_icon,
    Deimos,
    Tubarao,
    Ram,
    Rauora,
    Striker,
    Sentry,
    Skopos,
};
